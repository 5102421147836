<template>
  <div class='titlepage'>
      <div class="background">
          <transition name="fade" appear>
            <div class="image" :style="{backgroundImage:`url(${page.background})`}"/>
          </transition>
      </div>
      <article>
          <transition name="slide" appear>
            <div>
                <h1 v-html="title"/>
                <AttachmentUI template='titlepage' :page="page" @showVideo="showVideo"/>         
            </div>
          </transition>
      </article>
      <LinearButton :to="page?.previous" previous/>
      <LinearButton :to="page?.next"/>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>
</template>

<script>
import Page from '@/mixins/Page';
import LinearButton from '@/components/LinearButton';
import AttachmentUI from '@/components/AttachmentUI';
import Gallery from '@/components/Gallery';
export default {
    name:'Title Page',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        Gallery,
        AttachmentUI,
        LinearButton
    },
    data() {
        return {
            videoVisible:false,            
        }
    },
    computed : {
        title() {
            let title = this.page?.title;
            if(title) {
                const titleArray = title.split(" ");
                const firstWord = titleArray.shift();
                return titleArray.length === 0 ? `${firstWord}` : `${firstWord} <span class="nobr">${titleArray.join(" ")}</span>`;
            }
            return "";
        }
    }
}
</script>

<style lang="scss" scoped> 
    @media (max-width:699px) {  
        .linear {
            display:none;
        }
    }
    @media (min-width:700px) {    
        :deep() .nobr { white-space:nowrap; }   
        article {
            margin-top:-70px;
        }
    }
    .titlepage {
        display:grid;
        align-items: center;   
        
        position: relative;                    
        .background {            
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;            
            .image, &:after {
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;                
            }
            .image {
                background-size:cover;
                background-position: center;
            }
            &:after {
                backdrop-filter: blur(10px);
                background: linear-gradient(270deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.7) 100%);
                content: "";
            }
        }
    }    
    article {
        padding:13px;
        @media (min-width:505px) {
            padding:130px;
        }
        position: relative;
        > div {
            display: inline-block;
            max-width: 465px;
        }
        h1 {
            display: inline-block;
            border-top:1px solid #0066CC;
            border-bottom:1px solid #0066CC;
            color: #F5F5F5;
            font-size: 5.25rem;
            letter-spacing: 0;
            line-height: 0.9;
            text-transform: uppercase;
            padding-top:16px;
            padding-bottom:25px;
            font-family: 'Helvetica Neue LT Std';
            font-weight:900;
        }
    }     
</style>